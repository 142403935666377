<template>
   <v-container style="margin-top:160px;"  fluid>
     <v-card class="rounded-lg" elevation="10" style="position:fixed;top:80px;left:0;right:0; margin:auto;z-index:150;width:60%;">
          
          <v-card-text style="background-color:#fdaa47;color:white;text-align:center;">
             <span style="font-size:40px;padding:30px;">{{type_k[contract]}}</span><br>
             <span>Ваш поточний тип контракту </span>
        
         </v-card-text>

      
        
      </v-card>
       <!-- <v-chip 
        
        class=""
        style="position:fixed;z-index:10000;right:20px;bottom:20px;font-size:15px;"
        color="orange"
        text-color="white"
        ><center>{{this.skus_selected.length}}</center></v-chip> -->
      <v-card class="rounded-lg" elevation="10">
          <v-card-title style="background-color:orange;color:white;z-index:1000;margin-bottom:20px;justify-content:center;">Ваші SKU</v-card-title>
          <v-card-text>
              <v-row fluid justify="center" v-for="(item,index) in skus_available" :key="index">
                <v-col cols="4" style="background-color:#f7efe9;justify-content:center;">
                  <center>
                  <v-avatar left rounded style="height: 90px !important;  ">
                    <v-img :src="'img/pack_brands/' + item.img" style="height: 90px !important;  width: auto !important;" contain></v-img>
                  </v-avatar></center>
                  </v-col>
                  <v-col cols="8" style="display:flex; align-items:center;justify-content:center;background-color:#f7efe9"
                  @click.stop="
                                popup_cig_header = item.name;
                                popup_cig_img = item.img;
                                popup_cig_nic = item.desc;
                                popup_cig = true;
                              "
                  
                  
                  ><center>
                  <b style="font-size:18px;">{{ item.name }} </b><span  v-if="item.nec=='nec'&&1==2" style="color:orange;font-size:25px;">*</span></center>
                </v-col>
                
               <v-col cols="12" ><hr></v-col>
              </v-row>
         </v-card-text>

         <v-card-actions>

           <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button" type="submit" @click.native="$router.push({name:'Main'})">Закрити</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

         </v-card-actions>
        
      </v-card>
        
      <v-dialog v-model="popup_cig" style="z-index:1500">
      <v-card >
        <v-card-title class="headline grey lighten-2" style="justify-content: center;">
          {{ popup_cig_header }}
        </v-card-title>
        <br>
        <v-img :src="'img/pack_brands/' + popup_cig_img" style="max-height:400px;width:auto !important" contain></v-img>
        <v-card-text>
          <center>{{ popup_cig_nic }} мг.</center>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="popup_cig = false">Закрити</span>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </v-container> 
</template>
<style scoped>
.v-avatar {
  height: 90px !important;
}
.v-card__title {
  word-break: break-word !important;
}
</style>
<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
//import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
    component: "asssel",
  name: "asssel",
  
  //components:{btnsqr}, 
  watch: {
     
  },
  computed:{
     contract: function () {
      return this.$store.getters.contract;
    },
  },
  data: () => ({
     type_k:['','K-300','K-400','K-500'],
    skus_available:[],
    skus_selected:[],
    popup_cig: false,
    popup_cig_header: "",
    popup_cig_img: "",
    popup_cig_nic: "",
  }), 
  methods: {
    select_sku(item){
            this.skus_selected.push(item);            
           
            
            
    }
  },
  created() {
    
      this.$store.dispatch("profileset");
       this.$store.dispatch("page_title_set", "SKU");
    axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_list_period_my" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
          this.skus_available=resp.data;  
            console.log(this.skus_available)         
        
      });











  },
};
</script>